import request from "./request";

/**
 * 虚拟商品
 */

/**
 * 查询
 */
export const list = () => request.get("/vp/");
