<template>
  <Layout>
    <div class="app-vproductlist">
      <div class="tool">
        <a-upload
          :action="VP_UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :multiple="true"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <a-button> <a-icon type="upload" />上传图片 </a-button>
        </a-upload>
      </div>
      <div class="list-wrap">
        <div v-for="row in list" :key="row.id" class="item">
          <img :src="row.img" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { VP_UPLOAD_URL } from "@/service/request";
const VProductSvc = require("@/service/vproduct");

import uploadMixin from "@/mixins/upload";

export default {
  name: "VProductList",

  data() {
    return {
      VP_UPLOAD_URL,
      list: []
    };
  },

  mixins: [uploadMixin],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { id, img } = data;
        this.list = [{ id, img }, ...this.list];
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  async mounted() {
    try {
      this.list = await VProductSvc.list();
    } catch (err) {
      console.error(err);
      this.$message.error("未能正确加载图片，错误：" + err.message);
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-vproductlist {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
  }
  .list-wrap {
    width: 100%;
    background: #fff;

    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    .item {
      padding: 0 10px 10px 0;
    }

    img {
      border: 1px solid #fff;
      width: 160px;
      height: 160px;
    }
  }
}
</style>
